export const escapeHtml = (text: string) => {
  var map: {
    [key: string]: string;
  } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };
  return text.replace(/[&<>"']/g, m => {
    return map[m];
  });
};
